module.exports = [{
      plugin: require('/codebuild/output/src4174574090/src/node_modules/gatsby-plugin-facebook-analytics/gatsby-browser.js'),
      options: {"plugins":[],"appId":"690657684691629","version":"v3.3","xfbml":false,"cookie":false,"language":"pt_BR","includeInDevelopment":false,"debug":false},
    },{
      plugin: require('/codebuild/output/src4174574090/src/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fleury Medicina e Saúde | Excelência em Diagnóstico","short_name":"Fleury","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"../fleury/assets/images/icon.png","include_favicon":false},
    },{
      plugin: require('/codebuild/output/src4174574090/src/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src4174574090/src/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src4174574090/src/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src4174574090/src/packages/site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
